.w-200 {
    width: 200px;
}
.ml-10{
    margin-right: 10px;
}
.stepper-container div[style*="background-color: rgb(40, 167, 69);"]:before {
    content: '\2713';
    display: inline-block;
    color: #fff;
    padding: 0 6px 0 0;
    position: absolute;
    font-size: 24px;
    left: 9px;
    top: 2px;
}


.stepper-container div[style*="background-color: rgb(40, 167, 69);"] {
    position: relative;
}
.message {
    color: white;
    padding: 18px 20px;
    line-height: 26px;
    font-size: 16px;
    border-radius: 7px;
    margin-bottom: 30px;
    width: 74%;
    position: relative;
  }
  
  .admin-message {
    background: #286c45;
    text-align: left;
    float: left;
  }
  
  p.message-meta {
    font-size: 13px;
    text-align: right;
  }
  .message.user-message {
    color: black;
    background: #ccc;
    float: right;
    text-align: left;
  }
.chat-container {
    max-height: 350px;
    overflow-y: auto;
}
.dialog-content {
  max-height: 90vh;
  overflow-y: auto;
}
.notification-content .mr-4 {
  padding-top: 2px;
  text-transform: capitalize;
}
.p-3.vcc-page {
  max-width: 700px;
}
.menu_item {
  display: flex;
  align-items: center;
}

.menu_item .menu_icon {
  display: inline-block;
  margin-right: 10px;
  width: 30px;
}


.menu_item .menu_icon.icon_collapsed {
  padding-left: 8px;
}
.search_input input {
  padding-left: 35px!important;
  width: auto;
  max-width: 180px;
}
.project_id {
  padding-top: 10px;
}

.project_id input {
  margin-top: 10px;
  margin-bottom: 10px;
}

.btn button {
  width: 100%;
}
button[disabled] {
  opacity: 0.5;
}
.side-nav  .menu_item span {
  overflow: hidden;
  max-width: 205px;
  text-overflow: ellipsis;
}

